export default {
  isDev: process.env.REACT_APP_IS_DEV === "true",
  isTest: process.env.REACT_APP_IS_TEST === "true",
  caregiverUrl: process.env.REACT_APP_REDIRECT_URL_CAREGIVER,
  patientUrl: process.env.REACT_APP_REDIRECT_URL_PATIENT,
  apiUrl: process.env.REACT_APP_REDIRECT_URL_API,
  cookiePath: process.env.REACT_APP_COOKIE_PATH,
  zignsecUrl: process.env.REACT_APP_ZIGNSEC_URL,
  googleClientId:
    process.env.REACT_APP_GOOGLE_CLIENT_ID ||
    "195602586877-4n218lta1ckijga9b8ffbpbp1i29p30n.apps.googleusercontent.com",
  termsLink: "https://dinpsykolog.se/villkor",
  privacyPolicy: "https://dinpsykolog.se/integritetspolicy/",
  gtmId: "GTM-KJHD87Q",
  how_it_works: "https://dinpsykolog.se/#hur-det-funkar",
  faq: "https://dinpsykolog.se/#vanliga-fragor",
}
