import Cookie from "js-cookie"
import config from "config"
import { trackEvent } from "utils/trackEvent"

export const setCookie = ({ name, data, expires, url }) => {
  const domain = config.cookiePath
  const secure = !config.isDev
  const sameSite = config.isDev ? "" : "None"

  if (url) {
    if (config.caregiverUrl.includes(url)) {
      trackEvent("bankIdVerification", {
        isPatient: false,
      })
      Cookie.set("caregiver", JSON.stringify(data), {
        domain,
        secure,
        sameSite,
      })
      window.location.replace(url)
    }
    if (url.startsWith(config.patientUrl) || config.patientUrl.includes(url)) {
      trackEvent("bankIdVerification", {
        isPatient: true,
      })
      Cookie.set("patient", JSON.stringify(data), {
        domain,
        secure,
        sameSite,
      })
      window.location.replace(url)
    }
  } else {
    Cookie.set(name, data, {
      domain,
      secure,
      sameSite,
      expires,
    })
  }
}
