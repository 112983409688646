import { createTheme, alpha } from "@mui/material/styles"
import { grey } from "@mui/material/colors"

const basicTheme = createTheme({
  palette: {
    primary: {
      main: "#357376",
    },
    secondary: {
      main: "#000000",
    },
    default: {
      main: grey[300],
      dark: grey[400],
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeightSemiBold: 600,
  },
  breakpoints: {
    values: {
      base: 0,
      xs: 330,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})

const theme = createTheme(basicTheme, {
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained", color: "default" },
          style: {
            color: basicTheme.palette.getContrastText(
              basicTheme.palette.grey[300]
            ),
          },
        },
        {
          props: { variant: "outlined", color: "default" },
          style: {
            color: basicTheme.palette.text.primary,
            borderColor:
              basicTheme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.23)"
                : "rgba(255, 255, 255, 0.23)",
            "&.Mui-disabled": {
              border: `1px solid ${basicTheme.palette.action.disabledBackground}`,
            },
            "&:hover": {
              borderColor:
                basicTheme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.23)"
                  : "rgba(255, 255, 255, 0.23)",
              backgroundColor: alpha(
                basicTheme.palette.text.primary,
                basicTheme.palette.action.hoverOpacity
              ),
            },
          },
        },
        {
          props: { color: "default", variant: "text" },
          style: {
            color: basicTheme.palette.text.primary,
            "&:hover": {
              backgroundColor: alpha(
                basicTheme.palette.text.primary,
                basicTheme.palette.action.hoverOpacity
              ),
            },
          },
        },
      ],
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
  },
})

export default theme
