import React, { useEffect, useState, useCallback } from "react"
import { Box } from "@mui/material"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import { makeStyles } from "@mui/styles"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import theme from "theme"
import { getContent } from "api/content"
import { contentContext as ContentContext } from "contexts"
import { womanWithPhone } from "assets"
import loadable from "@loadable/component"
import OAuth from "views/OAuth"
import withClearCache from "./clearCache"

const useStyles = makeStyles(() => ({
  root: (height) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    minHeight: height - 64,
    backgroundImage: `linear-gradient(90.56deg, rgba(0, 0, 0, 0.53) 37.32%, rgba(0, 0, 0, 0) 125.65%),
    url(${womanWithPhone})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPositionX: "center",
    flexGrow: 1,
    CacheControl: "max-age=31536000, public",
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "center",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-end",
      backgroundImage: "none",
      backgroundSize: "100%",
      backgroundPosition: "top",
    },
  }),
}))

const BankIDComponent = loadable(() => import("views/BankID"))
const ChooseBankIDComponent = loadable(() => import("views/ChooseBankID"))
const HeaderComponent = loadable(() => import("components/Header"))

const ClearCacheComponent = withClearCache(MainApp)

function App() {
  return <ClearCacheComponent />
}

function MainApp() {
  const [height, setHeight] = useState(window.innerHeight)
  const [content, setContent] = useState({})
  const classes = useStyles(height)

  const handleHeight = useCallback((e) => {
    setHeight(e.target.innerHeight)
  }, [])

  useEffect(() => {
    try {
      const fetchContentData = async () => {
        const data = await getContent()
        setContent(data)
      }
      fetchContentData()
    } catch (error) {
      setContent({})
    }
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleHeight)
    return () => {
      window.removeEventListener("resize", handleHeight)
    }
  }, [handleHeight])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ContentContext.Provider value={content}>
          <HeaderComponent>
            <Box className={classes.root}>
              <Router>
                <Routes>
                  <Route path="/developer/login" element={<OAuth />} />
                  <Route path="/login/" element={<BankIDComponent />} />
                  <Route path="/" element={<ChooseBankIDComponent />} />
                </Routes>
              </Router>
            </Box>
          </HeaderComponent>
        </ContentContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
