import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from "react"
import ReactDOM from "react-dom"
import "index.css"
import App from "App"
import "typeface-montserrat"
import config from "config"
import TagManager from "react-gtm-module"

const { gtmId, isDev } = config
// Initialize Google Tag Manager
const tagManagerArgs = {
  gtmId,
}

if (!isDev) {
  TagManager.initialize(tagManagerArgs)
}

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
